<template>
  <div>
    <portal to="page-top-title">Blacklist #{{ id }}</portal>
    <portal to="page-top-right">
      <btn :route="{ name: 'blacklists-edit', params: { id: id } }" icon="fa-edit" text>Edit</btn>
    </portal>

    <box-single :url="`/blacklists/${id}`" data-prop="blacklist" resp-prop="data" loader>
      <template slot="content">
        <row>
          <column-info title="Name">
            <nullable :value="blacklist.name" text="Lost"/>
          </column-info>
          <column-info title="Contacts">
            <badge title="Total">{{ blacklist.emails_count }}</badge>
          </column-info>
          <column-info title="Created At">{{ blacklist.created_at }}</column-info>
          <column-info title="Updated At">{{ blacklist.updated_at }}</column-info>
        </row>
        <row v-if="blacklist.file">
          <column-info title="File Name">
            <nullable :value="blacklist.file.original_name" text="Lost"/>
          </column-info>
          <column-info title="Status">
            <badge-status :value="blacklist.file.status_text"/>
          </column-info>
          <column-info title="Rows Parsed">
            <badge title="Total">{{ blacklist.file.rows_parsed }}</badge>
          </column-info>
        </row>
      </template>
    </box-single>

    <box-table title="Emails" :url="`/blacklists/${id}/emails`" data-prop="emails" loader>
      <template slot="table-header">
        <tr>
          <th>ID</th>
          <th>Email</th>
          <th>Reason</th>
          <th class="text-right">Created At</th>
        </tr>
      </template>
      <template slot="table-body">
        <table-row v-for="email in emails" :key="`emails-tr-${email.id}`" :value="email">
          <td>{{ email.id }}</td>
          <td>{{ email.email_string }}</td>
          <td><badge-reason :value="email.reason_text"></badge-reason></td>
          <td class="text-right">{{ email.created_at }}</td>
        </table-row>
      </template>
    </box-table>
  </div>
</template>

<script>
import BoxSingle from '@/views/components/data/BoxSingle'
import Btn from '@/views/components/simple/Btn'
import Badge from '@/views/components/simple/Badge'
import BadgeStatus from '@/views/components/simple/BadgeStatus'
import Nullable from '@/views/components/simple/Nullable'
import BoxTable from '@/views/components/data/BoxTable'
import BadgeReason from '@/views/components/simple/BadgeReason'

export default {
  name: 'BlacklistsSingle',
  metaInfo () {
    return { title: 'Blacklist #' + this.id }
  },
  components: {
    BoxSingle,
    Btn,
    Badge,
    BadgeStatus,
    BadgeReason,
    BoxTable,
    Nullable
  },
  computed: {
    id () { return this.$route.params.id }
  },
  data () {
    return {
      blacklist: {},
      emails: []
    }
  }
}
</script>
